import React from 'react';
import { Link } from '@flogy/gatsby-theme-fgs-layout';
import useThemeOptions from '@flogy/gatsby-theme-fgs-legal/src/hooks/useThemeOptions';

const LegalNotice = () => {
  const themeOptions = useThemeOptions();

  return (
    <>
      <h2>Data Privacy</h2>
      <p>
        <Link href={themeOptions.dataPrivacy.path}>To the Data Privacy</Link>
      </p>
    </>
  );
};

export default LegalNotice;
