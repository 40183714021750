import React from 'react';
import SEO from '../../../../landing/components/seo';

const IntroText = () => (
  <>
    <SEO title="Legal Notice" />
    <h1>Legal Notice</h1>
  </>
);

export default IntroText;
